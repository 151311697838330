import React, { useState } from "react";
import poster from "../../assets/poster.png";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import CancelIcon from '@mui/icons-material/Cancel';
import footer from "../../assets/footer.png"
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate= useNavigate()
  const [phoneNumber, setPhoneNumber] = useState("");
  const [invalidNumber,setInvalidNumber]=useState(false)
  const handleChange = (event) => {
     setInvalidNumber(false)
    // Allow only numbers and limit to 10 digits
    const value = event.target.value.replace(/[^0-9]/g, "");
    if (value.length <= 10) {
      setPhoneNumber(value);
    }

  };

  const HandleClick=()=>{
    if(phoneNumber.length===10){
      navigate(`/recharge/${phoneNumber}`)
    }
    else{
      setInvalidNumber(true)
    }
  }

  return (
    <div>
      <img src={poster} alt="poster" style={{ width: "100%" }} />
      <h2
        style={{
          marginTop: "8px",
          marginBottom: "-15px",
        }}
      >
        Get set, connect
      </h2>
      <div style={{ padding: "25px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            padding: "2px",
            borderBottom: "2px solid #0e3cc9",
          }}
        >
          <RememberMeIcon />
          <p style={{ fontWeight: 500 }}>Mobile Recharge</p>
        </div>
        <div
          style={{
            background: "#0e3cc9",
            borderRadius: "15px",
            padding: "20px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              background: "#0a2885",
              padding: "4px",
              borderRadius: "25px",
              width: "120px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                color: "white",
                background: "#0e3cc9",
                borderRadius: "20px",
                padding: "7px",
              }}
            >
              Prepaid
            </div>
          </div>
          <input
            style={{
              border: "none",
              padding: "12px",
              borderRadius: "10px",
              width: "90%",
              marginTop: "10px",
            }}
            type="tel"
            value={phoneNumber} //  phoneNumber only holds 10 digits
            onChange={handleChange}
            placeholder="+91 9999999999" // Example placeholder, including country code
            maxLength="10" // Allow for only 10 digits
          />
          {invalidNumber && <div style={{display:"flex",gap:"4px",alignItems:"center",marginBottom:"-15px",marginTop:"-5px"}}>
            <CancelIcon style={{color:"red",fontSize:"12px"}}/>
            <p style={{fontSize:"12px",color:"red"}}>Plese enter a valid mobile number!</p>
          </div>}
          <p style={{ color: "white", textAlign: "center", fontSize: "12px" }}>
            Recharge with a ₹499 plan for a year full of digital delights.
          </p>

          <button
            onClick={HandleClick}
            style={{
              color: "#0e3cc9",
              fontWeight: "bold",
              border: "none",
              background: "white",
              padding: "10px",
              width: "100%",
              borderRadius:"30px",
              fontSize:"15px"
            }}
          >
            Recharge
          </button>
        </div>

      </div>
      <img src={footer} alt="footer" style={{width:"100%"}} />
    </div>
  );
};

export default Home;
