import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Recharge from "./components/Recharge/Recharge"
import Payment from './components/Payment/Payment';
import ScrollToTop from './components/scrolltotop/ScrolltoTop';
function App() {
  return (
    <div className="App">
    <Navbar/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/recharge/:mb" element={<ScrollToTop><Recharge/></ScrollToTop>}/>
      <Route path="/payment/:amount" element={<ScrollToTop><Payment/></ScrollToTop>}/>
    </Routes>
    </div>
  );
}

export default App;
