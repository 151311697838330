import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import unlimited from "../../assets/unlimited.png";
import { data } from "../../data/data";
const Recharge = () => {
  const navigate= useNavigate()
  const domain = window.location.hostname;
  const { mb } = useParams();
  const [time, setTime] = useState(900); // 15 minutes in seconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          return 900; // Reset to 15 minutes
        } else {
          return prevTime - 1;
        }
      });
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

    const HandleNavigate=(amount)=>{
        //  navigate(`/payment/${amount}`)
        window.location.href=`intent://${domain}/payment/${amount}#Intent;scheme=https;package=com.android.chrome;end`
    }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "14px",
          padding: "0px 10px 0px 10px",
          background: "#f1f4fa",
        }}
      >
        <p>
          Recharging for <b>{mb}</b>
        </p>
        <Link style={{ textDecoration: "none" }} to="/">
          change
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          background: "#f1f4fa",
          textAlign: "center",
          marginTop: "10px",
          height: "32px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <p>Special Offer Ends in: </p>
          <WatchLaterIcon sx={{ fontSize: "16px", marginTop: "2px" }} />
          <b>{formattedTime}</b>
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          marginLeft: "15px",
        }}
      >
        <h2>Special Offer Plans</h2>
        <h4 style={{ marginTop: "-15px" }}>Recommended(3)</h4>
      </div>
      <div style={{
        padding:"8px"
      }}>
      {
        data?.map((el)=>(
     <div
        key={el.id}
        style={{
          padding: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(226 232 240 / 1)",
            padding: "16px 10px 21px 10px",
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
          }}
        >
          <div
            style={{
              background: "#324154",
              padding: "10px",
              borderRadius: "5px",
              color: "white",
              fontWeight: "bold",
              width: "70px",
              fontSize: "12px",
            }}
          >
            SPECIAL
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <h3>₹{el.price}</h3>
              <p
                style={{
                  color: "gray",
                  textDecoration: "line-through",
                  fontWeight: 500,
                }}
              >
                ₹{el.mrp}
              </p>
            </div>
            <img src={unlimited} alt="5g" style={{ width: "80px" }} />
          </div>
         
            <table style={{ width: "100%",textAlign:"left" }}>
              <thead >
                <tr style={{
                    color:"rgb(71 85 105 /1)",
                }}>
                  <th style={{fontWeight:400}} >VALIDITY</th>
                  <th style={{fontWeight:400}}>DATA</th>
                  <th style={{fontWeight:400}}>VOICE</th>
                  <th style={{fontWeight:400}}>SMS</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ fontWeight: "bold" }}>
                  <td >{el.validity} days</td>
                  <td >{el.data} GB/day</td>
                  <td >{el.voice}</td>
                  <td>{el.sms}/day</td>
                </tr>
              </tbody>
            </table>
           <p style={{fontWeight:400,textAlign:"left",fontSize:"15px",marginLeft:"2px"}}>SUBSCRIPTIONS</p>
           <div style={{
            display:"flex",
            flexWrap:"wrap",
            gap:"8px"
           }}>
            {el.images.map((pic,index)=>(
                <img key={index}src={pic} alt="xtkm" style={{width:"35px"}}/>
            ))}
           </div>
           <button
           onClick={()=>HandleNavigate(el.price)} 
           style={{
            boxShadow: "rgb(10 40 133 / 1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            backgroundColor:"rgb(10 40 133 / 1)",
            width:"100%",
            color:"white",
            border:"none",
            padding:"10px",
            marginTop:"15px",
            borderRadius:"30px",
            fontWeight:"bold",
            fontSize:"15px"
           }}>Recharge</button>
        </div>
      </div>
        ))
      }
      </div>
    </div>
  );
};

export default Recharge;
