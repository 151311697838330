import React from 'react'
import { useParams } from 'react-router-dom'
import googlepay from "../../assets/googlepay.svg"
import paytm from "../../assets/paytm.svg"
import securepayment from "../../assets/securepayment.svg"
import { paymentDetails } from '../upistore/UpiStore'


const Payment = () => {
    const {amount}=useParams()
    const handleBuyClickByGpay = () => {
          if (!window.PaymentRequest) {
            return;
          }
      
          const supportedInstruments = [
            {
              supportedMethods: ["https://tez.google.com/pay"],
              data: {
                pa: paymentDetails.gpay,
                pn: "Jio Mobile Recharge",
                tr: "1234ABCD",
                url: "https://yourwebsite.com/order/1234ABCD",
                mc: "1234",
                tn: "Paying to Jio",
              },
            },
          ];
      
          const details = {
            total: {
              label: "Total",
              amount: {
                currency: "INR",
                value: amount,
              },
            },
            displayItems: [
              {
                label: "Original Amount",
                amount: {
                  currency: "INR",
                  value: amount,
                },
              },
            ],
          };
      
          const request = new PaymentRequest(supportedInstruments, details);
          request.show();
        
       
    };
    const handleBuyClickByPaytm=()=>{
        window.location.href=`paytmmp://cash_wallet?pa=${paymentDetails.paytm}&pn=Jio&am=${amount}.00&tr=AkBIIGH2MkMGf5olejI&mc=8931&cu=INR&tn=Paying_to_Jio_Payments&sign=AAuN7izDWN5cb8A5scnUiNME+LkZqI2DWgkXlN1McoP6WZABa/KkFTiLvuPRP6/nWK8BPg/rPhb+u4QMrUEX10UsANTDbJaALcSM9b8Wk218X+55T/zOzb7xoiB+BcX8yYuYayELImXJHIgL/c7nkAnHrwUCmbM97nRbCVVRvU0ku3Tr&featuretype=money_transfer`
    }
  return (
    <div style={{
        border:"1px solid lightgrey",
        borderRadius:"10px",
        padding:"20px 10px 40px 10px",
        margin:"20px",
        textAlign:"center"
    }}>
    <h4>Pay ₹{amount} using Google Pay to get extra 20% cashback</h4>
     
     <div style={{
        display:"flex",
        flexDirection:"column",
        gap:"15px"
     }}>
        <div 
      onClick={handleBuyClickByGpay}
        style={{
            border:"2px solid lightgrey",
            borderRadius:"10px",
            display:"flex",
            alignItems:"center",
            gap:"10px",
            justifyContent:"center"
        }}>
            <img src={googlepay} alt="gpay" style={{width:"60px"}}/>
            <h4>Google Pay</h4>
        </div>
        <div 
         onClick={handleBuyClickByPaytm}
        style={{
            border:"2px solid lightgrey",
            borderRadius:"10px",
            display:"flex",
            alignItems:"center",
            gap:"10px",
            justifyContent:"center"
        }}>
            <img src={paytm} alt="gpay" style={{width:"60px"}}/>
            <h4>Paytm</h4>
        </div>
     </div>
      <h3 style={{
         color:"#0e3cc9"
      }}>Secure Payment System By Jio</h3>
      <img src={securepayment} alt="scg" style={{
        width:"200px"
      }} />
    </div>
  )
}

export default Payment