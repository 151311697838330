import amazonprime from "../assets/amazonprime.svg"
import disney from "../assets/disney.svg"
import jiocinema from "../assets/jiocinema.svg"
import jiocloud from "../assets/jiocloud.svg"
import jiosavan from "../assets/jiosavan.svg"
import jiotv from "../assets/jiotv.svg"
import netflix from "../assets/netflix.svg"
import sony from "../assets/sony.svg"
import zee5 from "../assets/zee5.svg"



export const data =[
    {
        id:1,
        validity:364,
        data:"3.0",
        voice:"Unlimited",
        sms:100,
        mrp:2999,
        price:499,
        images:[jiotv,jiocinema,jiocloud,jiosavan,amazonprime,netflix,disney,sony,zee5]

    },
    {
        id:2,
        validity:84,
        data:"2.0",
        voice:"Unlimited",
        sms:100,
        mrp:1099,
        price:249,
        images:[jiotv,jiocinema,jiocloud,jiosavan,amazonprime,]

    },
    {
        id:3,
        validity:84,
        data:"1.5",
        voice:"Unlimited",
        sms:100,
        mrp:666,
        price:199,
        images:[jiotv,jiocinema,jiocloud]

    },
]