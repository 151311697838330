import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from "../../assets/logo.png"
import "./navbar.css"
const Navbar = () => {
  return (
    <div style={{
        backgroundColor:"#0e3cc9",
    }}>
        <div style={{
            display:"flex",
            alignItems:"center",
            padding:"20px",
            gap:"15px",
            height:"32px"

        }}>
        <MenuIcon sx={{
            color:"white"
        
        }}/>
        <img src={logo} alt="jio_logo" style={{
            width:"30px",
            height:"30px"
        }}/>
        <div style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            width:" 100%",
        }}>
                <input className='navinput' style={{
                        background: "#0a2885",
                        border: "none",
                        borderRadius: "1.625rem",
                        fontSize: "1rem",
                        height: "2.4rem",
                        outline: "none",
                        padding: "0 1.5rem 0 1rem",
                        width:" 100%",
                        color:"white"
                }} type="text" name="searchQueryInput" placeholder="Search" value=""/>
                <button style={{
                        background: "none",
                        border:"none",
                        height: "2.8rem",
                        marginLeft: "-2.5rem",
                        outline: "none",
                        width: "3.5rem",
                        marginTop:"2px"
                }} type="submit" name="searchQuerySubmit">
                    <svg style={{height:"24px",width:"24px"}} viewBox="0 0 24 24">
                        <path fill="#fff" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path>
                    </svg>
                </button>
            </div>
        <AccountCircleIcon sx={{
            color:"white"
        
        }}/>
        </div>
    </div>
  )
}

export default Navbar